import { useNavigate, useLocation } from 'react-router-dom';

export function UseNavitageToPage(){
    const navigate = useNavigate();
    const location = useLocation();

    const NavigateToPageFromPageName = (pageName, sessionId) =>{
        console.log( `Logging data from navigate: `,pageName, sessionId);
        switch (pageName.toLowerCase()) {
            case "hockey":
                navigate(`/Hockey?sessionid=${sessionId}`);
                break;
            case "shuffleboard":
                navigate(`/ShuffleBoard?sessionid=${sessionId}`);
                break;
            case "paperfootball":
                navigate(`/PaperFootball?sessionid=${sessionId}`);
                break;
            case "sumo":
                navigate(`/Sumo?sessionid=${sessionId}`);
                break;
            case "soccer":
                navigate(`/Soccer?sessionid=${sessionId}`);
                break;
            case "golf":
                navigate(`/Golf?sessionid=${sessionId}`);
                break;
            default:
                // Handle default case or throw error for invalid pageName
                navigate(`/Lobby?sessionid=${sessionId}`);
                break;
        }
    }

    const tryNavigatePage = (payload, playerId, sessionId) => {
        //console.log(`I am in the nav to page method`, payload);
        
        if(getCurrentPage() != payload.additionalData){
            console.log("Page was diff");
            if(payload.targetPlayerGUID === playerId){
                console.log("Its me and now trying to go to correct page");
                NavigateToPageFromPageName(payload.additionalData, sessionId)
            }
        }
    }

    function getCurrentPage() {
        const pathname = location.pathname;
    
        // Split the pathname by '/' and get the first segment
        const initialPath = pathname.split('/')[1];
    
        return initialPath;
    }

    return {NavigateToPageFromPageName, tryNavigatePage};
}


