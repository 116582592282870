import React from "react";
import "./LoadingSpinnerStyle.css"

export default function LoadingSpinner(props){
  const {size, thickness} = props;

  const LoaderBox = {
    display: 'flex',
    position: 'relative',
    margin: 'auto auto',
    width: `${size}px`,
    height: `${size}px`,
}

const SpinnerOverwrites = {
  borderWidth: thickness ? `${thickness}px` : '10px'
}

  return(
    <div style={{...LoaderBox}}>
      <div className="loadingSpinner" style={{...SpinnerOverwrites}} />
    </div>
  )
}