import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner, PlayerIcon, TextBox } from "../Components/PagePrefabs";
import { CarcadeLogo, SplashBackgroundImg } from "../Sprites/ImagesLoader.js"



export default function JoinScreen(props){
    const context = useContext(Context);
    const [inputValue, setInputValue] = useState('');
    const [textValue, setTextValue] = useState('Enter Code to Join Lobby');


    useEffect(() => {
        //OnMount Things happen here
        document.title = "Join Carcade";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        console.log(context.user);
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        //Disconnect();
    }

   
    function onPayload(id, payload){
        console.log(`Payload message recieved ${id} ${payload}`)

        //Game specific calls
        switch(id){
            default:
                break;
        }
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };
    const handleButtonClick = () => {
        alert(`Text: ${textValue}, Input: ${inputValue}`);
        // You can add more functionality here
      };
    return (
        <>
        <PageBackground
            bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"}
            bkgImage={SplashBackgroundImg} // Replace with your actual image
            useBkgGradient={true}
            useLogo={true}
            Logo={CarcadeLogo} // Replace with your actual logo
            useSmallLogo={false}
        />
        <div style={{
            position: 'relative',
            zIndex: 1, // Ensure this content is on top
            padding: '20px',
            textAlign: 'center',
            color: 'white' // Change text color for visibility against the background
        }}>
            <div style={{ marginBottom: '10px', fontSize: '18px' }}>
                {"Enter Code to Join Lobby"}
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Type your input here..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ marginRight: '10px', padding: '8px' }} // Add some padding for better appearance
                />
                <button onClick={handleButtonClick}>Submit</button>
            </div>
        </div>
    </>
        


    );
}

//        <PageBackground bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"} bkgImage={SplashBackgroundImg} useBkgGradient useLogo Logo={CarcadeLogo} useSmallLogo  />

