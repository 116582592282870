import React, {useCallback} from "react";
import './App.css';
import websocket from "./helpers/WebSocket";
import { BrowserRouter, Route, Routes,  Link } from "react-router-dom";
import GameContextProvider from './context/GameContextProvider';
import Hockey from "./gamecontrollers/hockeyController";
import Soccer from "./gamecontrollers/soccerController";
import PaperFootball from "./gamecontrollers/paperFootballController";
import SlotCar from "./gamecontrollers/SlotCarController";
import ShuffleBoard from "./gamecontrollers/shuffleBoardController";
import Sumo from "./gamecontrollers/sumoController_new";
import Golf from "./gamecontrollers/golfController";
import Lobby from "./gamecontrollers/Lobby";
import ChuckIt from "./gamecontrollers/ChuckItController";
import JoinScreen from "./gamecontrollers/JoinScreen";

export default function App() {
  console.log("App.js things happen here");
  
  const FixedScreen = {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    position: 'fixed',

    userSelect: 'none',
    WebkitUserSelect: 'none', 
    MozUserSelect: 'none', /* Firefox */
    MsUserSelect: 'none', /* Internet Explorer/Edge */
  }

  return (
    
    <div style={{...FixedScreen}}>
    <GameContextProvider>
        <BrowserRouter>
          <Routes>
          <Route path="/Play" element={<JoinScreen/>} exact/>
            <Route path="/Lobby" element={<Lobby/>} exact/>
            <Route path="/Hockey" element={<Hockey/>} exact/>
            <Route path="/Soccer" element={<Soccer/>} exact/>
            <Route path="/Sumo" element={<Sumo/>} exact/>
            <Route path="/PaperFootball" element={<PaperFootball/>} exact/>
            <Route path="/SlotCar" element={<SlotCar/>} exact/>
            <Route path="/ShuffleBoard" element={<ShuffleBoard/>} exact/>
            <Route path="/Golf" element={<Golf/>} exact/>
            <Route path="/ChuckIt" element={<ChuckIt/>} exact/>
            <Route path="/" element={<JoinScreen exact/>} />
          </Routes>
        </BrowserRouter>
    </GameContextProvider>
    </div>
  
  );  
}


/*
<BrowserRouter>
      <Routes>
        <Route path="/" component={LandingPageClass} exact/>
        <Route path="/CarRace" component={CarRace} exact/>
        </Routes>
    </BrowserRouter>
*/


/*
 <nav>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/user/:id">User</Link>
        </div>
      </nav>
*/

/*
        <Route path="/CarRace/:id" component={CarRace} />

*/


