import React, { useEffect, useContext, useState, useRef, useMemo } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import { useNetworkingHooks } from "../helpers/NetworkMessaging";
import { CoreGameConnectionsHooks } from "../helpers/CoreGameConnections_Hooks";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import { PageBackground, PageLanding, PlayerBanner } from "../Components/PagePrefabs";
import { GenericReticule, shuffleboardBkgSplash, shuffleboardLogo, shuffleboardTouchArea, sumoLogo } from "../Sprites/ImagesLoader";
import { TeamSelection } from "../Components/TeamSelectionPrefabs";
import { GameplayArea } from "../Components/GameplayPrefabs";
import { TextBox } from "../Components/PagePrefabs.js";


export default function ShuffleBoard(props){ 
    const context = useContext(Context);
    
    const useTeamSelectionDataContext = useTeamSelectionData();
    const TeamsRef = useRef();
    TeamsRef.current = useTeamSelectionDataContext.Teams;
    
    const useGameStatecontext = useGameState();
    const GameStateRef = useRef();
    GameStateRef.current = useGameStatecontext.GameState;

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context.user, useGameStatecontext, useTeamSelectionDataContext);

    const [Invert, setInvert]= useState(false);
    const [SliderValue, setSliderValue] = useState(50);

    const MyTeam = useMemo(() => {return useTeamSelectionDataContext.Teams.myteam}, [useTeamSelectionDataContext.Teams]);
    const OtherTeam = useMemo(() => {return useTeamSelectionDataContext.Teams.otherteam}, [useTeamSelectionDataContext.Teams]);
    const Spectating = useMemo(() => {return useTeamSelectionDataContext.Teams.myteam === useTeamSelectionDataContext.Teams.neutralteam }, [useTeamSelectionDataContext.Teams]);
    
    useEffect(() => {
        //OnMount Things happen here
        document.title = "Shuffleboard Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        
        JoinWaitingRoom();

        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});
        
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

    function onPayload(id, payload, senderContext){
        
        //console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload, senderContext);

        //Game specific calls
        switch(id){
            case "TeamSetup":
                SetupShuffleboardController(payload);
                break;
            default:
                break;
        }
    }

    function SetupShuffleboardController(payload)
    {
        useTeamSelectionDataContext.SetTeam_Home({color: payload.homeTeam.color.substring(0, payload.homeTeam.color.length - 2), name: payload.homeTeam.name});
        useTeamSelectionDataContext.SetTeam_Away({color: payload.awayTeam.color.substring(0, payload.awayTeam.color.length - 2), name: payload.awayTeam.name});
    }
    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("Shuffleboard_Aim", payload);
    }

    function TrackingEnded(coords){
        
        var msg = {
            coords: coords,
            slider: SliderValue
        }

        SendSpecificMessageToServer("Shuffleboard_Fire", msg);
    }

    function UpdateSliderValue(value){
        setSliderValue(value);
    }

    const TouchInfo = {
        bkg: shuffleboardTouchArea,
        reticule: GenericReticule,
        sendArray: true,
        rate: 15,
        onUpdateTracking: TrackingPosition,
        onTrackingEnd: TrackingEnded

    }
    

    return (
        <>
            {GameStateRef.current.isOnGameLandingPage ? 
                <PageLanding bkgImage={shuffleboardBkgSplash} useLogo Logo={shuffleboardLogo} />
                :
                <>
                <PageBackground bkgImage={shuffleboardBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!GameStateRef.current.joinedGame} Logo={shuffleboardLogo}/>            
                <PlayerBanner />
                {!GameStateRef.current.teamsSelected ? 
                    <TeamSelection TeamContext={useTeamSelectionDataContext} GameState={GameStateRef.current} smts={(msg) => SendSpecificMessageToServer(msg)} /> 
                    : 
                    <GameplayArea game="Shuffleboard" Team={MyTeam} OpposingTeam={OtherTeam} TouchBoxInfo={TouchInfo} useBlur={!GameStateRef.current.myTurn} GetSliderValue={(value) => UpdateSliderValue(value) } Spectating={Spectating} Logo={shuffleboardLogo} />    
                }
                </>
            }
        </>



    );
}
