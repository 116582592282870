import React, { useState, useContext, useMemo } from "react";
import Context from "../context/Context.js";
import "../Styles/FigmaStyle.css";
import { SendSpecificMessageToServer } from  "../helpers/NetworkMessaging";
import { GetTeamIcon, GetPlayerBackgroundAsColor, GetTeamlessGameNameAsColor } from "../Sprites/PlayerIconLoader.js";
import { TextBox } from "./PagePrefabs.js";


export function TeamSelection(props) {
const {TeamContext, smts, GameState} = props

return(
        <>
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>

            <div style={{position: "relative", width: "90%", height: "100%", margin: '0 auto', display: 'flex', flexFlow: "column nowrap", justifyContent:'flex-start', gap: '12px'}}>
                <div style={{position: 'relative', width: '100%', height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TextBox text={"Select your team"} width={"100%"} textAlign={"center"} fontSize={'xx-large'} textStyle={{color: 'white'}}/>
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TeamSelectionButton onClickButton={() => TeamContext.SelectTeamToPlay("Home")} Selected={TeamContext.Teams.myteam.color} text={TeamContext.Teams.hometeam.name} Team={TeamContext.Teams.hometeam} />
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TeamSelectionButton onClickButton={() => TeamContext.SelectTeamToPlay("Away")} Selected={TeamContext.Teams.myteam.color} text={TeamContext.Teams.awayteam.name} Team={TeamContext.Teams.awayteam} />
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TeamSelectionButton onClickButton={() => TeamContext.SelectTeamToPlay("Neutral")} Selected={TeamContext.Teams.myteam.color} text={"SPECTATING"} Team={TeamContext.Teams.neutralteam} />
                </div>                
            </div>
            {
                GameState.canStart ? 
                
                <div style={{position: 'absolute', bottom: '15%', left: '10%', width: "80%", height: "60px", margin: '0 auto'}}>
                    <StartGameButton onClickButton={() => smts("RequestToStartGame")} />
                </div> : 
                <div style={{position: 'absolute', bottom: '10%', left: '5%', width: "90%", height: "100px", margin: '0 auto'}}>
                    <div style={{width: '100%', height: '35%'}}>
                        <TextBox width={'100%'} text={"Waiting for players"} fontSize={'xx-large'} textAlign={'center'} textStyle={{textShadow: '0px 2px 2px rgba(0,0,0, .5)'}} />  
                    </div>
                    <div style={{width: '100%', height: '35%'}}>
                        <TextBox width={'100%'} text={"2 / 2"} fontSize={'48pt'} textAlign={'center'} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600', textShadow: '0px 1px 2px rgba(0,0,0, .5)'}} />
                    </div>
                    
                    
                      
                </div>
            }
            
        </div>
        </>
    );
}

export function TeamSelectionTeamless(props) {
    const {userColor, smts, GameState, TeamContext} = props

    const [selected, setSelected] = useState(1);
    const myColor = useMemo(() => {return GetPlayerBackgroundAsColor(userColor)}, [userColor])



    const onClick = (button) => {
        setSelected(button);

        if(button == 0) TeamContext.SelectTeamToPlay("Teamless")
        if(button == 1) TeamContext.SelectTeamToPlay("Neutral")
    }
    
    return(
            <>
            <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>
    
                <div style={{position: "relative", width: "90%", height: "100%", margin: '0 auto', display: 'flex', flexFlow: "column nowrap", justifyContent:'flex-start', gap: '12px'}}>
                    <div style={{position: 'relative', width: '100%', height: "80px", display: 'flex', margin: '0 auto'}}>
                        <TextBox text={"Ready to play?"} width={"100%"} textAlign={"center"} fontSize={'xx-large'} textStyle={{color: 'white'}}/>
                    </div>
                    <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                        <TeamSelectionButtonTeamless onClickButton={() => {onClick(0)}} Selected={selected === 0} myColor={myColor} text={"READY"} />
                    </div>
                    <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                        <TeamSelectionButtonTeamless onClickButton={() => {onClick(1)}} Selected={selected === 1} myColor={'#7d7f7c'} text={"SPECTATING"} />
                    </div>
                    <div style={{position: 'relative', width: "90%", height: "100px", display:'flex', margin: '15% auto', flexFlow: "column nowrap", justifyContent:'flex-start', gap: '12px'}}>
                        <div style={{width: '100%', height: '35%'}}>
                            <TextBox width={'100%'} text={"Players Ready"} fontSize={'xx-large'} textAlign={'center'} textStyle={{textShadow: '0px 2px 2px rgba(0,0,0, .5)'}} />  
                        </div>
                        <div style={{width: '100%', height: '35%'}}>
                            <TextBox width={'100%'} text={"1"} fontSize={'48pt'} textAlign={'center'} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600', textShadow: '0px 1px 2px rgba(0,0,0, .5)'}} />
                        </div>  
                    </div>         
                </div>
                {
                    GameState.canStart ? 
                    
                    <div style={{position: 'absolute', bottom: '15%', left: '10%', width: "80%", height: "60px", margin: '0 auto'}}>
                        <StartGameButton onClickButton={() => smts("RequestToStartGame")} />
                    </div> : <></> 
                }
            </div>
            </>
        );
    }

function TeamSelectionButton(props){
    const{ Team, onClickButton, Selected, text} = props;

    const DarkColor = useMemo(() => {return `color-mix(in srgb, ${Team.color}, black 50%)`}, [Team.color]);
    const ButtonSelected = useMemo(() => {return Team.color === Selected}, [Team, Selected]);
    
    const selectionBox = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        background: `linear-gradient(to right, ${Team.color} 10%, ${!ButtonSelected ? DarkColor : Team.color} 90%)`,
        border: 'inset 1px solid rgba(0,0,0, .5)',
        outline: !ButtonSelected ? '' : 'outset 3px #FFD600' ,
        outlineOffset: '2px',
        boxShadow: ButtonSelected ? "inset 0px 0px 16px 8px rgba(200,200,200, .1)" : "0px 2px 2px 2px rgba(0,0,0, .375)" ,
        borderRadius: '8px'

    }

    const ClickedButton = () => {
        if (Boolean(window.navigator.vibrate)) {
            window.navigator.vibrate(100);
        }

        onClickButton();
    }

    return(
    <div onClick={() => {ClickedButton()}} style={{...selectionBox}}>
        <TeamIcon size={86} name={Team.name} />
        {
            <TextBox width={'100%'} text={text.toUpperCase()} textAlign={'right'} textStyle={{marginRight: '20px', textShadow: ButtonSelected  ? '2px 2px 2px black': ''}} />
        }
        

    </div>);
}

function TeamSelectionButtonTeamless(props){
    const{onClickButton, myColor, Selected, text} = props;

    const DarkColor = useMemo(() => {return `color-mix(in srgb, ${myColor}, black 50%)`}, [myColor]);
    
    const selectionBox = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        background: `linear-gradient(to right, ${myColor} 10%, ${!Selected ? DarkColor : myColor} 90%)`,
        border: 'inset 1px solid rgba(0,0,0, .5)',
        outline: !Selected ? '' : 'outset 3px #FFD600' ,
        outlineOffset: '2px',
        boxShadow: Selected ? "inset 0px 0px 16px 8px rgba(200,200,200, .1)" : "0px 2px 2px 2px rgba(0,0,0, .375)" ,
        borderRadius: '8px'

    }

    const ClickedButton = () => {
        if (Boolean(window.navigator.vibrate)) {
            window.navigator.vibrate(100);
        }

        onClickButton();
    }

    return(
    <div onClick={() => {ClickedButton()}} style={{...selectionBox}}>
        {
            <TextBox width={'100%'} text={text.toUpperCase()} textAlign={'right'} textStyle={{marginRight: '20px', textShadow: Selected  ? '2px 2px 2px black': ''}} />
        }
        

    </div>);
}

export function TeamSelectionBanner(props){
    const{ Team } = props;

    const DarkColor = useMemo(() => {return `color-mix(in srgb, ${Team.color}, black 50%)`}, [Team]);
    
    const banner = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '48px',
        textAlign: 'center',
        background: `linear-gradient(to right, ${Team.color} 65%, ${DarkColor})`,
        overflow: 'hidden'
    }

    return(
        <div style={{...banner}}>
            <TeamIcon size={256} name={Team.name} largeIcon blur={'.125rem'} opacity={'15%'}  />
            {
                <TextBox width={'100%'} text={Team.name.toUpperCase()} textAlign={'right'} textStyle={{marginRight: '20px', textShadow: '2px 2px 2px black'}} />
            }
            
    
        </div>);
}

export function GameSelectionBanner(props){
    const{ Game } = props;

    const GameColor = useMemo(() => {return GetTeamlessGameNameAsColor(Game.name)}, [Game]);
    const DarkColor = useMemo(() => {return `color-mix(in srgb, ${GameColor}, black 25%)`}, [GameColor]);
    

    const banner = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '48px',
        textAlign: 'center',
        justifyContent: 'center',
        background: `linear-gradient(to bottom, ${GameColor} 50%, ${DarkColor})`,
        overflow: 'hidden'
    }

    return(
        <div style={{...banner}}>
            <TeamIcon size={40} name={Game.name} notSquare={true} bkgStyle={{width: '72px', margin: 'auto auto'}} />          
        </div>);
}

export function TeamIcon(props)
{
    const {size, name, largeIcon, blur, opacity, notSquare, bkgStyle} = props;

    const MyIcon = useMemo(() => {return GetTeamIcon(name); }, [name]);

    const container ={
    display: 'flex', 
    flex: 'row nowrap',
    flexShrink: '0',
    justifyContent: 'center',
    height: size +'px' ?? '100%',
    width: size +'px' ?? '100%',
    marginTop: largeIcon ? `calc(-${size * .325}px )` : '-4px',
    marginLeft: largeIcon ? `calc(-${size * .05}px )` : '8px',
    }

    const bkg = {
        display: 'flex',
        position: 'relative',
        height: '100%',
        width: '100%',
        aspectRatio: "1/1", 
        filter: (blur ? `blur(${blur})` : '') + (opacity ? `opacity(${opacity})` : '')           
    }

    return(
        <div style={{...container, ...bkgStyle}}>
            {MyIcon != null ? <img id="TeamIcon" src={MyIcon ?? ''} style={{...bkg}} /> : <></>}
        </div>
        
    );
}


function StartGameButton(props){
    const{onClickButton} = props;
   
    const selectionBox = {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        background: `linear-gradient(to bottom, ${'yellow'} 37.5%, ${'orange'})`,
        border: 'inset 1px solid rgba(0,0,0, .5)',
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .375)",
        borderRadius: '8px'

    }

    const ClickedButton = () => {
        if (Boolean(window.navigator.vibrate)) {
            window.navigator.vibrate(100);
        }

        onClickButton();
    }

    return(
    <div onClick={() => {ClickedButton()}} style={{...selectionBox}}>
        <TextBox width={'100%'} text={"START GAME"} fontSize={'x-large'} textAlign={'center'} textStyle={{color: 'black', textShadow: '0px 1px 2px rgba(0,0,0, .25)'}} />   
    </div>);
}