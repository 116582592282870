import { loadImage } from "../helpers/dom";

//0
import golfBkgSplash from "../Sprites/TinygolfControllerSprites/GolfBkgSplash.png";
import golfTouchArea from "../Sprites/TinygolfControllerSprites/GolfTouchArea.png";
import golfLogo from "../Sprites/TinygolfControllerSprites/GolfLogo.png";

//1
import hockeyLogo from "../Sprites/HockeyControllerSprites/HockeyLogo.png";
import hockeyBkgSplash from "../Sprites/HockeyControllerSprites/HockeyBkgSplash.png";
import hockeyTouchArea from "../Sprites/HockeyControllerSprites/HockeyTouchArea.png";
//2
import shuffleboardLogo from "../Sprites/ShuffleboardSprites/ShuffleboardLogo.png"
import shuffleboardBkgSplash from "../Sprites/ShuffleboardSprites/ShuffleboardBkgSplash.png";
import shuffleboardTouchArea from "../Sprites/ShuffleboardSprites/ShuffleboardTouchArea.png";
//3
import sumoLogo from "../Sprites/SumoControllerSprites/SumoLogo.png";
import sumoBkgSplash from "../Sprites/SumoControllerSprites/SumoBkgSplash.png";
import sumoTouchArea from "../Sprites/SumoControllerSprites/SumoTouchArea.png";
//4
import soccerLogo from "../Sprites/SoccerControllerSprites/SoccerLogo.png";
import soccerBkgSplash from "../Sprites/SoccerControllerSprites/SoccerBkgSplash.png";
import soccerTouchArea from "../Sprites/SoccerControllerSprites/SoccerTouchArea.png";
//5
import paperfootballLogo from "../Sprites/PaperFootballControllerSprites/PaperFootballLogo.png";
import paperfootballBkgSplash from "../Sprites/PaperFootballControllerSprites/PaperFootballBkgSplash.png";
import paperfootballTouchArea from "../Sprites/PaperFootballControllerSprites/PaperFootballTouchArea.png";

//Generic
import SplashBackgroundImg from "../Sprites/Generic/SplashBkg.png";
import CarcadeLogo from "../Sprites/Generic/CarcadeLogo_01.png";
import GenericReticule from "../Sprites/Generic/GenericReticle.png";




export const preloadImages = async () => {
    await Promise.all(
      [
       golfLogo,    
       golfBkgSplash,
       golfTouchArea,
       hockeyLogo,
       hockeyBkgSplash,
       hockeyTouchArea,
       shuffleboardLogo,
       shuffleboardBkgSplash,
       shuffleboardTouchArea,
       soccerLogo,
       soccerBkgSplash,
       soccerTouchArea,
       sumoLogo,
       sumoBkgSplash,
       sumoTouchArea,
       paperfootballLogo,
       paperfootballBkgSplash,
       paperfootballTouchArea,
       SplashBackgroundImg,
       CarcadeLogo,
       GenericReticule
      ].map(loadImage),
    )
  
    void Promise.all(
      [     
        golfLogo,    
       golfBkgSplash,
       golfTouchArea,
        hockeyLogo,
        hockeyBkgSplash,
        hockeyTouchArea,
        shuffleboardLogo,
        shuffleboardBkgSplash,
        shuffleboardTouchArea,
        soccerLogo,
        soccerBkgSplash,
        soccerTouchArea,
        sumoLogo,
        sumoBkgSplash,
        sumoTouchArea,
        paperfootballLogo,
        paperfootballBkgSplash,
        paperfootballTouchArea,
          SplashBackgroundImg,
        CarcadeLogo,
        GenericReticule,
      
      ].map(loadImage),
    )
  }
  
  export {     
    golfLogo,    
    golfBkgSplash,
    golfTouchArea,
    hockeyLogo,
    hockeyBkgSplash,
    hockeyTouchArea,
    shuffleboardLogo,
    shuffleboardBkgSplash,
    shuffleboardTouchArea,
    soccerLogo,
    soccerBkgSplash,
    soccerTouchArea,
    sumoLogo,
    sumoBkgSplash,
    sumoTouchArea,
    paperfootballLogo,
    paperfootballBkgSplash,
    paperfootballTouchArea,
    SplashBackgroundImg,
    CarcadeLogo,
    GenericReticule
  
  }
